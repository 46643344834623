const defaultTypes = {
  isImage: false,
  is3D: false,
  is2D: false,
  isOffice: false,
  isOther: false,
  isVedio: false,
  isTxt: false,
  isPdf: false,
};
export const types = defaultTypes;
export const initFileTypes = (formater) => {
  const dt = { ...defaultTypes };
  switch (formater) {
    case "png":
    case "jpg":
    case "jpeg":
    case "bmp":
    case "gif":
      dt.isImage = true;
      break;
    case "mp4":
    case "ogg":
    case "avi":
    case "flv":
      dt.isVedio = true;
      break;
    case "pdf": // 需要特殊处理
      dt.isPdf = true;
      break;
    case "xls":
    case "xlsx":
    case "doc":
    case "docx":
    case "ppt":
    case "pptx":
      dt.isOffice = true;
      break;
    case "txt":
    case "xml":
    case "html":
    case "htm":
    case "json":
    case "js":
    case "cs":
    case "cshtml":
    case "vbs":
    case "ps1":
    case "md":
      dt.isTxt = true;
      break;
    case "3ds":
    case "3dm":
    case "3dxml":
    case "catpart":
    case "igs":
    case "iges":
    case "ipt":
    case "obj":
    case "qobj":
    case "ply":
    case "prt":
    case "sab":
    case "sat":
    case "sldasm":
    case "sldprt":
    case "stl":
    case "stp":
    case "step":
    case "x_t":
    case "x_b":
      dt.is3D = true;
      break;
    default:
      dt.isOther = true;
      break;
  }
  return dt;
};
