<template>
  <video
    class="innerPreview"
    :autoplay="false"
    :loop="false"
    :muted="false"
    :src="doc.filePreviewAddress"
    :controls="true"
  ></video>
</template>

<script>
export default {
  props: {
    doc: { type: Object, default: () => {} },
  },
};
</script>
<style lang="scss" scoped>
.innerPreview {
  width: 100%;
  height: 100%;
}
</style>
