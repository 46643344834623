<template>
  <!--
  <a-empty description="暂不支持预览PDF" />

  -->
  <iframe v-if="previewSrc" :src="previewSrc" class="innerPreview"> </iframe>
</template>

<script>
const url = process.env.VUE_APP_API_BASE_URL;
export default {
  props: {
    doc: { type: Object, default: () => {} },
  },
  computed: {
    previewSrc() {
      if (!this.doc || !this.doc.docUrl) {
        return "";
      }
      const path = encodeURIComponent(this.doc.docUrl);
      return `${url}/api/Common/file/transfer/stream/${path}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.innerPreview {
  width: 100%;
  height: 100%;
}
</style>
