<template>
  <div
    v-if="isConverting"
    style="
      text-align: center;
      vertical-align: middle;
      height: 10em;
      margin-top: 3em;
    "
  >
    <a-spin :spinning="true"> </a-spin>
    <p>正在转换...</p>
  </div>
  <iframe v-else-if="isConverted" :src="previewSrc" class="innerPreview">
  </iframe>
  <a-empty v-else :description="`转换失败`"></a-empty>
</template>

<script>
import { pollStatusAsync } from "@/api/3D.js";
export default {
  props: {
    doc: { type: Object, default: () => {} },
  },
  data() {
    return {
      myDoc: this.doc,
    };
  },
  methods: {
    waitConverting() {
      setTimeout(() => {
        pollStatusAsync(this.myDoc.id)
          .then((res) => {
            if (res.previewUrl) {
              this.myDoc.filePreviewAddress = res.previewUrl;
              if (!this.myDoc.extraProperties) this.myDoc.extraProperties = {};
              this.myDoc.extraProperties.convert3DStatus = 2;
            } else {
              this.waitConverting();
            }
          })
          .catch(() => {
            this.waitConverting();
          });
      }, 1000 * 5);
    },
  },
  computed: {
    previewSrc() {
      if (this.isConverted) {
        return this.myDoc.filePreviewAddress;
      }
      return "";
    },
    getProperties() {
      if (!this.myDoc || !this.myDoc.extraProperties) return {};
      return this.myDoc.extraProperties;
    },
    isConverting() {
      return this.getProperties.convert3DStatus == 1;
    },
    isConverted() {
      return this.getProperties.convert3DStatus == 2;
    },
  },
  watch: {
    doc: {
      handler() {
        this.myDoc = { ...this.doc };
        setTimeout(() => {
          if (this.isConverting) {
            this.waitConverting();
          }
        }, 100);
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.innerPreview {
  width: 100%;
  height: 100%;
}
</style>
