<template>
  <img :src="doc.filePreviewAddress" class="innerPreview" />
</template>

<script>
export default {
  props: { doc: { type: Object, default: () => {} } },
};
</script>

<style lang="scss" scoped>
.innerPreview {
  width: 100%;
  height: 100%;
}
</style>
