/* eslint-disable no-unused-vars */
import {
  postRequest,
  updateRequest,
  deleteRequest,
  getRequest,
} from "@/utils/http";

export const pollStatusAsync = (fileId) =>
  postRequest("/YunPingTai/Preview3d/pollConvert", { taskId: fileId });
