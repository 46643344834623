<template>
  <!--
  <div>
    <a-card>
      <a-icon slot="cover" type="file-text" style="font-size: 100px" />
      <div style="text-align: center">
        <div>由于尚未设置阿里Oss跨域，因此，不可预览</div>
        <a :href="doc.docUrl" target="_blank">下载文档</a>
      </div>
    </a-card>
  </div>
  <div>
    {{ content }}
  </div>
  -->
  <textarea class="innerPreview" :value="content" disabled="disabled">
  </textarea>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";
import qs from "qs";
import store from "@/store";
import notification from "ant-design-vue/es/notification";
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  timeout: 30000, // 请求超时时间
});

export default {
  props: {
    doc: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      content: "",
    };
  },
  created() {
    this.loadContent();
  },
  methods: {
    loadContent() {
      //todo: 等待ali oss 配置跨域
      if (this.doc.docUrl) {
        const config = {
          url: this.doc.docUrl,
          method: "get",
          headers: {
            "Content-Type": "text/html; charset=utf-8",
          },
        };
        request(config).then(({ status, statusText, data }) => {
          if (status == 200) {
            this.content = data;
          } else {
            this.content = statusText;
          }
        });
      }
    },
  },
  watch: {
    doc: {
      handler() {
        this.loadContent();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.innerPreview {
  width: 100%;
  height: 100%;
}
</style>
