<template>
  <div class="container">
    <template v-if="types.isOffice">
      <MyOffice :doc="doc" />
    </template>
    <template v-else-if="types.isImage">
      <MyImage :doc="doc" />
    </template>
    <template v-else-if="types.isVedio">
      <MyVedio :doc="doc" />
    </template>
    <template v-else-if="types.isTxt">
      <MyTxt :doc="doc" />
    </template>
    <template v-else-if="types.isPdf">
      <MyPdf :doc="doc" />
    </template>
    <template v-else-if="types.is3D">
      <My3D :doc="doc" @refresh="$emit('refresh')" />
    </template>
    <template v-else-if="types.isOther">
      <MyOther :doc="doc" />
    </template>
    <template v-else>
      <a-empty />
    </template>
  </div>
</template>

<script>
import MyVedio from "./MyVedio.vue";
import MyOffice from "./MyOffice.vue";
import MyTxt from "./MyTxt.vue";
import MyImage from "./MyImage.vue";
import MyOther from "./MyOther.vue";
import MyPdf from "./MyPdf.vue";
import My3D from "./My3D.vue";
import { types, initFileTypes } from "./FileTypes";
export default {
  name: "filePreview",
  props: {
    doc: { type: Object, default: () => {} },
  },
  components: {
    MyVedio,
    MyOffice,
    MyTxt,
    MyImage,
    MyOther,
    MyPdf,
    My3D,
  },
  data: () => {
    return { types: { ...types } };
  },
  created() {
    this.tryInitDocType();
  },
  methods: {
    tryInitDocType() {
      if (!this.doc || !this.doc.docFormat) {
        Object.assign(this.types, types);
      } else {
        const tp = initFileTypes(this.doc.docFormat);
        Object.assign(this.types, tp);
      }
    },
  },
  watch: {
    doc: {
      handler() {
        this.tryInitDocType();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .innerPreview {
    width: 100%;
    height: 100%;
  }
}
</style>
