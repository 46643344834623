import { message } from "ant-design-vue";
export default {
  /**
   * @description 文本复制
   * @param {String Number} value 复制的内容
   */
  copyText(value) {
    var input = document.createElement("input");
    input.value = value;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length),
      document.execCommand("Copy");
    document.body.removeChild(input);
    message({ message: "复制成功", type: "success" });
  },
};

function findComponetByRef($current, refName, $children) {
  if ($current.$refs && $current.$refs[refName]) {
    return $current.$refs[refName];
  }
  $children.push(...$current.$children);
}

// 树状结构 从左至右 逐级查找
function findComponetByRefInChildren($children, refName) {
  if ($children && $children.length) {
    const childs = [];
    for (let index = 0; index < $children.length; index++) {
      const element = $children[index];
      const rs = findComponetByRef(element, refName, childs);
      if (rs) return rs;
    }
    {
      const rs = findComponetByRefInChildren(childs, refName);
      if (rs) return rs;
    }
  }
}

export const findLeftBar = ($self) => {
  const $root = $self.$root;
  const $children = [];
  const fd = findComponetByRef($root, "leftBar", $children);
  if (fd) return fd;
  return findComponetByRefInChildren($children, "leftBar");
};
