<template>
  <iframe v-if="previewSrc" :src="previewSrc" class="innerPreview"> </iframe>
</template>

<script>
export default {
  props: {
    doc: { type: Object, default: () => {} },
  },
  computed: {
    previewSrc() {
      if (!this.doc || !this.doc.filePreviewAddress) {
        return "";
      }
      const href = this.doc.filePreviewAddress;
      const removeProtocol = href.replace(/http[s]?:/, "");
      return `${location.protocol}${removeProtocol}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.innerPreview {
  width: 100%;
  height: 100%;
}
</style>
